import { FC, useContext } from 'react';
import { UserContext } from 'src/app/providers/UserProvider';
import { Container } from 'src/entity/Container';
import { ProfileNavigation } from 'src/feature/ProfileNavigation';
import { Button, Input } from 'src/shared/ui';

export const AboutPage: FC = () => {
  const { user } = useContext(UserContext);

  return (
    <Container>
      <div className="flex flex-1">
        <div className="hidden h-full w-72 flex-shrink-0 d-xs:block">
          <ProfileNavigation />
        </div>

        <div className="flex flex-1 flex-col d-xs:ml-5">
          <div className="font-megasansj-400 mb-10 text-3xl">Личные данные</div>

          <div className="max-w-96">
            <Input classNames="mb-4" label="Имя" value="Иван" disabled />
            <Input classNames="mb-4" label="Фамилия" value="Иванов" disabled />
            <Input classNames="mb-8" label="Отчество" value="Иванович" disabled />

            <div className="font-megasansj-300 mb-1 text-xs text-grey-500">Дата рождения</div>
            <div className="mb-8 flex gap-2">
              <Input value="30" classNames="w-20 min-w-20" disabled />
              <Input value="июля" disabled />
              <Input value="1996" classNames="w-24 min-w-24" disabled />
            </div>

            <Input classNames="mb-4" label="Номер телефона" value="8 987 999 99 99" disabled />
            <Input classNames="mb-8" label="E-mail" value={user?.email} disabled />

            <Button variant="disabled" disabled>
              Сохранить изменения
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};
