import { FC } from 'react';
import Select from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

interface AppSelectProps extends StateManagerProps {}

export const AppSelect: FC<AppSelectProps> = ({ ...props }) => {
  return (
    <Select
      isSearchable={false}
      {...props}
      styles={{
        container: provided => ({
          ...provided,
          width: '100%',
        }),
        control: (provided, state) => ({
          ...provided,
          borderRadius: '8px',
          borderColor: '#bec9d1 !important',
          height: '36px',
          fontSize: '14px',
          lineHeight: '20px',
          fontFamily: "'MegaSansJ Regular', sans-serif",
          cursor: 'pointer',
          boxShadow: state.menuIsOpen ? '0 2px 8px 0 #08090a14' : 'none',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        menuList: provided => ({
          ...provided,
          padding: 0,
          boxShadow: '0 2px 8px 0 #08090a14',
          borderRadius: '8px',
        }),
        option: (provided, state) => ({
          ...provided,
          padding: '8px 14px',
          fontSize: '14px',
          lineHeight: '20px',
          fontFamily: "'MegaSansJ Regular', sans-serif",
          cursor: 'pointer',
          backgroundColor: state.isFocused ? '#f2f5f7' : '#FFF',
          color: state.isSelected ? '#68419e' : '#000',
        }),
      }}
    />
  );
};
