import React, { createContext, FC, ReactNode, useState } from 'react';
import { UserType } from 'src/shared/api/auth';
import { userContextDefaultState } from 'src/shared/store/user/constants';
import { IUserContext } from 'src/shared/store/user/types';

export const UserContext = createContext<IUserContext>(userContextDefaultState);

interface IUserProviderProps {
  children: ReactNode;
}

export const UserProvider: FC<IUserProviderProps> = ({ children }) => {
  const { user: _user, isUserLoading: _isUserLoading } = userContextDefaultState;

  const [user, setUser] = useState<UserType | null>(_user);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(_isUserLoading);

  const handleSetUser = (user: UserType | null) => {
    if (!user) {
      return setUser(null);
    }

    setUser(user);
  };

  return (
    <UserContext.Provider value={{ user, setUser: handleSetUser, isUserLoading, setIsUserLoading }}>
      {children}
    </UserContext.Provider>
  );
};
