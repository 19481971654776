import { TokenType } from 'src/shared/types';

import { localStorageKey } from './constants';

const getSessionToken = (): string | undefined => {
  const data = localStorage.getItem(localStorageKey);

  if (!data) {
    return;
  }

  const token = JSON.parse(data);

  return token?.sessionId;
};

const getRefreshToken = (): string | undefined => {
  const data = localStorage.getItem(localStorageKey);

  if (!data) {
    return;
  }

  const token = JSON.parse(data);

  return token?.refreshToken;
};

const getAccessToken = (): string | undefined => {
  const data = localStorage.getItem(localStorageKey);

  if (!data) {
    return;
  }

  const token = JSON.parse(data);
  return token?.token;
};

const getTokens = (): TokenType | undefined => {
  const data = localStorage.getItem(localStorageKey);

  if (!data) {
    return;
  }

  const token = JSON.parse(data);

  if (!token?.refreshToken || !token?.token) {
    return;
  }

  return {
    token: token.token,
    refreshToken: token.refreshToken,
  };
};

const setTokens = (token: TokenType) => {
  localStorage.setItem(localStorageKey, JSON.stringify({ ...token }));
};

const removeTokens = () => {
  localStorage.removeItem(localStorageKey);
};

const TokenService = {
  getTokens,
  getAccessToken,
  getRefreshToken,
  getSessionToken,
  removeTokens,
  setTokens,
};

export default TokenService;
