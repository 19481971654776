import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { UserContext } from 'src/shared/store';
import { bannersContextDefaultState } from 'src/shared/store/banners/constants';
import { BannerType, IBannerContext } from 'src/shared/store/banners/types';

export const BannersContext = createContext<IBannerContext>(bannersContextDefaultState);

interface ICatalogProviderProps {
  children: ReactNode;
}

export const BannersProvider: FC<ICatalogProviderProps> = ({ children }) => {
  const { user } = useContext(UserContext);

  const [banners, setBanners] = useState<BannerType[]>([]);
  const [isBannersLoading, setIsBannersLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        const { data } = await CatalogService.getBanners();
        setBanners(data);
      } catch (e) {
      } finally {
        setIsBannersLoading(false);
      }
    })();
  }, [user]);

  return (
    <BannersContext.Provider
      value={{
        banners,
        setBanners,
        isBannersLoading,
        setIsBannersLoading,
      }}
    >
      {children}
    </BannersContext.Provider>
  );
};
