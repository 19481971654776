import { FC, InputHTMLAttributes } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cn from 'classnames';

interface InputProps extends InputHTMLAttributes<Element> {
  classNames?: string;
  error?: string;
  register?: UseFormRegisterReturn<string>;
  label?: string;
}

export const Input: FC<InputProps> = ({ label, classNames, register, error, ...props }) => {
  const isError = Boolean(error);

  return (
    <div className={cn({ 'w-full': !Boolean(classNames?.includes('w-')) }, 'relative', classNames)}>
      {label && <div className="font-megasansj-300 mb-1 text-xs text-grey-500">{label}</div>}

      <input
        {...register}
        {...props}
        className={cn(
          {
            'border-grey-400 hover:border-grey-500 focus:border-violet-100': !isError,
            'border-red-100 placeholder-red-100': isError,
          },
          'font-megasansj-300 h-10 w-full rounded-md border px-2.5 text-sm transition-all',
        )}
      />

      {isError && (
        <div className="font-megasansj-300 absolute left-0 top-11 text-extra-xs text-red-100">
          {error}
        </div>
      )}
    </div>
  );
};
