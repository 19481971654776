import { FC } from 'react';
import cn from 'classnames';
import { addSpaces } from 'src/shared/lib/utils';

interface DiscountPriceProps {
  price: string;
  big?: boolean;
}

export const DiscountPrice: FC<DiscountPriceProps> = ({ price, big }) => {
  return (
    <div
      className={cn(
        { 'text-xs': !Boolean(big), 'text-lg': Boolean(big) },
        'font-megasansj-500 relative w-fit whitespace-nowrap text-grey-500',
      )}
    >
      {`${addSpaces(+price.replace(',', '.'))} ₽`}
      <div
        className={cn(
          {
            'top-1.5': !Boolean(big),
            'top-3': Boolean(big),
          },
          'absolute left-0 w-full border border-red-200',
        )}
      />
    </div>
  );
};
