import { FC, MouseEvent } from 'react';
import cn from 'classnames';
import { ProductType } from 'src/shared/api/catalog';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { ReactComponent as HeartIcon } from 'src/shared/assets/icons/kit/heart.svg';
import { ReactComponent as HeartFullIcon } from 'src/shared/assets/icons/kit/heart-full.svg';

interface FavoriteButtonProps {
  data: ProductType;
  onClick?: (data: ProductType) => void;
  text?: boolean;
  classNames?: string;
}

export const FavoriteButton: FC<FavoriteButtonProps> = ({ data, onClick, text, classNames }) => {
  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      if (data.favorite) {
        await CatalogService.deleteFavorite(data.id);
      } else {
        await CatalogService.setFavorite(data.id);
      }

      onClick && onClick(data);
    } catch (e) {}
  };

  return (
    <button className={cn('flex items-center', classNames)} onClick={handleClick}>
      {data.favorite ? (
        <HeartFullIcon className="h-4 w-4 fill-violet-100 hover:fill-violet-100" />
      ) : (
        <HeartIcon
          className={cn(
            { 'fill-grey-500': !data.favorite, 'fill-violet-100': data.favorite },
            'h-4 w-4 hover:fill-violet-100',
          )}
        />
      )}
      {text && (
        <div className="font-megasansj-400 ml-2 text-sm text-grey-500">
          {data.favorite ? 'В избранном' : 'В избранное'}
        </div>
      )}
    </button>
  );
};
