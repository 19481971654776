import React, { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MainContainer, ProvidersContainer } from 'src/app/containers';
import { routes } from 'src/app/routes';
import { routesEnum } from 'src/shared/const';

export const App: FC = () => {
  return (
    <BrowserRouter>
      <ProvidersContainer>
        <MainContainer>
          <Routes>
            {routes.map(route => (
              <Route key={`route-${route.path}`} path={route.path} element={route.element} />
            ))}

            <Route path="*" element={<Navigate to={routesEnum.CATALOG} />} />
          </Routes>
        </MainContainer>
      </ProvidersContainer>
    </BrowserRouter>
  );
};
