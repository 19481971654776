import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface TitleProps {
  classNames?: string;
  children: ReactNode;
  variant?: 'base' | 'sm';
}

export const Title: FC<TitleProps> = ({ children, classNames, variant = 'base' }) => {
  return (
    <div
      className={cn(
        {
          'font-megasansj-600 text-2xl d-xs:text-3xl': variant === 'base',
          'font-megasansj-400 text-xl d-xs:text-2xl': variant === 'sm',
          'mb-5': !Boolean(classNames?.includes('mb-')),
        },
        classNames,
        'text-black-100',
      )}
    >
      {children}
    </div>
  );
};
