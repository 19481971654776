import React, { FC, ReactNode } from 'react';
import {
  BannersProvider,
  BasketProvider,
  CatalogProvider,
  GroupProvider,
  UserProvider,
} from 'src/shared/store';

interface ProvidersContainerProps {
  children?: ReactNode;
}

export const ProvidersContainer: FC<ProvidersContainerProps> = ({ children }) => {
  return (
    <UserProvider>
      <BannersProvider>
        <BasketProvider>
          <GroupProvider>
            <CatalogProvider>{children}</CatalogProvider>
          </GroupProvider>
        </BasketProvider>
      </BannersProvider>
    </UserProvider>
  );
};
