import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'src/entity/Container';

export const PaymentPage: FC = () => {
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }

    const checkout = new window['YooMoneyCheckoutWidget']({
      confirmation_token: id,
      return_url: `https://agregator-front-dev.bots.winsolutions.ru/end_payment`,
      error_callback: err => {
        console.error('Ошибка при оплате');
      },
    });

    checkout.render('payment-form').then(() => {});
  }, []);

  return (
    <Container title="Оплата заказа">
      <div className="flex-1" id="payment-form"></div>
    </Container>
  );
};
