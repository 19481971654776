import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/app/providers/UserProvider';
import BasketService from 'src/shared/api/basket/BasketService';
import { ProductType } from 'src/shared/api/catalog';
import { basketContextDefaultState } from 'src/shared/store/basket/constants';
import { IBasketContext } from 'src/shared/store/basket/types';

export const BasketContext = createContext<IBasketContext>(basketContextDefaultState);

interface ICatalogProviderProps {
  children: ReactNode;
}

export const BasketProvider: FC<ICatalogProviderProps> = ({ children }) => {
  const { user } = useContext(UserContext);

  const [basket, setBasket] = useState<ProductType[]>([]);
  const [isBasketLoading, setIsBasketLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        setIsBasketLoading(true);

        const { data } = await BasketService.getBasket(user.id);
        setBasket(data);
      } catch (e) {
        setBasket([]);
      } finally {
        setIsBasketLoading(false);
      }
    })();
  }, [user]);

  return (
    <BasketContext.Provider
      value={{
        basket,
        setBasket,
        isBasketLoading,
        setIsBasketLoading,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};
