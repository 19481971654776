import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { BannerType } from 'src/shared/store';

import 'swiper/css';

interface BannersListProps {
  list: BannerType[];
  classNames?: string;
}

export const BannersList: FC<BannersListProps> = ({ list, classNames }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [_, setCur] = useState<number>(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.style.transform = `translateX(0)`;

    setInterval(() => {
      setCur(prev => {
        if (!ref.current) {
          return prev;
        }

        if (prev === list.length - 1) {
          ref.current.style.transform = `translateX(0)`;
          return 0;
        }

        ref.current.style.transform = `translateX(-${100 * (prev + 1)}%)`;

        return prev + 1;
      });
    }, 8000);
  }, []);

  return (
    <div
      ref={ref}
      className={cn('flex h-full w-full transform rounded-xl transition duration-1000', classNames)}
    >
      {list.map(({ image }, i) => (
        <img
          key={`banner-${i}`}
          alt=""
          src={image}
          className="h-full w-full min-w-full object-fill"
        />
      ))}
    </div>
  );
};
