import { FC, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { UserContext } from 'src/app/providers/UserProvider';
import AuthService from 'src/shared/api/auth/AuthService';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/kit/check.svg';
import { routesEnum } from 'src/shared/const';
import { Button, Input, Title } from 'src/shared/ui';
import * as yup from 'yup';

type RegisterType = {
  login: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const schema = yup.object().shape({
  login: yup.string().required('Заполните поле'),
  phone: yup.string().required('Заполните поле').matches(phoneRegExp, 'Телефон некорректный'),
  email: yup.string().email('Email некорректный').required('Заполните поле'),
  password: yup.string().required('Заполните поле'),
  passwordConfirmation: yup
    .string()
    .required('Заполните поле')
    .oneOf([yup.ref('password')], 'Пароли не совпадают'),
});

export const RegisterPage: FC = () => {
  const navigate = useNavigate();
  const { user, isUserLoading } = useContext(UserContext);

  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (!isUserLoading && user) {
      navigate(routesEnum.CATALOG);
    }
  }, [isUserLoading, user]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RegisterType>({ mode: 'onBlur', resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<RegisterType> = async params => {
    if (isSubmitting) {
      return;
    }

    try {
      await AuthService.register(params);
      navigate(routesEnum.LOGIN);
    } catch (e) {
    } finally {
      navigate(routesEnum.CATALOG);
    }
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center p-4">
      <Title variant="sm" classNames="mb-12">
        Регистрация
      </Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-10 flex w-full max-w-[32rem] flex-col items-center"
      >
        <Input
          classNames="mb-6"
          register={register('login')}
          error={errors.login?.message}
          placeholder="Имя"
        />

        <Input
          classNames="mb-6"
          register={register('phone')}
          error={errors.phone?.message}
          placeholder="Телефон"
        />

        <Input
          classNames="mb-6"
          register={register('email')}
          error={errors.email?.message}
          placeholder="Email"
        />

        <Input
          classNames="mb-6"
          register={register('password')}
          error={errors.password?.message}
          placeholder="Пароль"
          type="password"
        />

        <Input
          classNames="mb-10"
          register={register('passwordConfirmation')}
          error={errors.passwordConfirmation?.message}
          placeholder="Подтвердите пароль"
          type="password"
        />

        <Button classNames="w-80 mb-5" variant="violet" size="lg">
          Зарегистрироваться
        </Button>

        <div className="flex w-80 cursor-pointer" onClick={() => setCheck(prev => !prev)}>
          <div
            className={cn(
              { 'bg-violet-100': check },
              'mr-2 flex h-6 w-6 items-center justify-center rounded border border-violet-100',
              'flex-shrink-0 transition-all',
            )}
          >
            <CheckIcon className="h-4 w-4 stroke-white" />
          </div>
          <div className="font-megasansj-300 text-xs text-grey-500">
            Нажимая на кнопку Зарегистрироваться вы соглашаетесь на обработку персональных данных
          </div>
        </div>
      </form>

      <div className="font-megasansj-300 flex">
        <div className="mr-2 text-black-100">Уже есть аккаунт?</div>
        <Link to={routesEnum.LOGIN} className="text-violet-100 underline">
          Войти
        </Link>
      </div>
    </div>
  );
};
