import { AxiosResponse } from 'axios';
import { ApiAuth } from 'src/shared/api/ApiAuth';
import TokenService from 'src/shared/api/token/TokenService';
import { LoginType, RegisterType, TokenType } from 'src/shared/types';

import { GetUserResponse, LoginResponse, LogoutResponse, RefreshParams } from './types';

const login = (params: LoginType) =>
  ApiAuth.post<string, Promise<AxiosResponse<LoginResponse>>, LoginType>(`/api/Auth/login`, params);

const register = (params: RegisterType) =>
  ApiAuth.post<string, Promise<AxiosResponse<LoginResponse>>, RegisterType>(
    `/api/Auth/register`,
    params,
  );

const logout = () => {
  const sessionId = TokenService.getSessionToken() || '';

  return ApiAuth.post<string, Promise<AxiosResponse<LogoutResponse>>, { sessionId: string }>(
    `/api/Auth/logout`,
    {
      sessionId,
    },
  );
};

const logoutReverse = () => {
  const sessionId = TokenService.getSessionToken() || '';

  return ApiAuth.post<string, Promise<AxiosResponse<LogoutResponse>>, { sessionId: string }>(
    `/api/Auth/logout-reverse`,
    {
      sessionId,
    },
  );
};

const removeUser = () =>
  ApiAuth.post<string, Promise<AxiosResponse<LogoutResponse>>, void>(`/api/Auth/delete-user`);

const refresh = (params: RefreshParams) =>
  ApiAuth.post<string, Promise<AxiosResponse<TokenType>>, RefreshParams>(
    `/api/Auth/refresh`,
    params,
  );

const getUser = () =>
  ApiAuth.get<void, Promise<AxiosResponse<GetUserResponse>>>(`/api/Auth/get-username`);

const AuthService = { register, login, logout, refresh, getUser, logoutReverse, removeUser };

export default AuthService;
