import { FC } from 'react';
import { ProductType } from 'src/shared/api/catalog';
import { ReactComponent as MinusIcon } from 'src/shared/assets/icons/kit/minus.svg';
import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/kit/plus.svg';
import { useAddToBasket } from 'src/shared/lib/hooks';
import { Button } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

interface ProductAddButtonProps {
  product: ProductType;
}

export const ProductAddButton: FC<ProductAddButtonProps> = ({ product }) => {
  const { addToBasket, removeOneFromBasket, isLoading, amount } = useAddToBasket(product);

  return amount ? (
    <div className="mt-4 flex h-9 w-fit items-center rounded-full bg-grey-300">
      <button className="flex h-full items-center px-4" onClick={removeOneFromBasket}>
        <MinusIcon className="h-3 w-3 fill-violet-100" />
      </button>
      <div className="flex w-9 min-w-9 justify-center">
        {isLoading ? (
          <Loader classNames="w-5 h-5" stroke="#8654cc" />
        ) : (
          <div className="font-megasansj-400 text-center text-black-100">{amount}</div>
        )}
      </div>
      <button className="flex h-full items-center px-4" onClick={addToBasket}>
        <PlusIcon className="h-3 w-3 fill-violet-100" />
      </button>
    </div>
  ) : (
    <Button variant="violet" classNames="m-md:w-44 w-full mt-4" onClick={addToBasket}>
      {isLoading ? <Loader classNames="w-5 h-5" /> : 'Купить'}
    </Button>
  );
};
