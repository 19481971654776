import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { BasketContext } from 'src/app/providers/BasketProvider';
import { UserContext } from 'src/app/providers/UserProvider';
import { ProfileNavigation } from 'src/feature/ProfileNavigation';
import { ReactComponent as BurgerIcon } from 'src/shared/assets/icons/kit/burger.svg';
import { ReactComponent as CatalogIcon } from 'src/shared/assets/icons/kit/catalog.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { ReactComponent as HeartIcon } from 'src/shared/assets/icons/kit/heart.svg';
import { ReactComponent as ShoppingCartIcon } from 'src/shared/assets/icons/kit/shopping-cart.svg';
import { ReactComponent as LogoIcon } from 'src/shared/assets/icons/logo.svg';
import { routesEnum } from 'src/shared/const';
import { Button } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

export const Header: FC = () => {
  const navigate = useNavigate();

  const { user, isUserLoading } = useContext(UserContext);
  const { basket } = useContext(BasketContext);

  const [isTop, setIsTop] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const checkScroll = () => setIsTop(window?.scrollY === 0);

  useEffect(() => {
    document.addEventListener('scroll', checkScroll);

    return () => {
      document.removeEventListener('scroll', checkScroll);
    };
  }, []);

  const iconStyles = 'w-5 h-5 fill-grey-500';

  const links = [
    { path: routesEnum.CATALOG, icon: <CatalogIcon className={iconStyles} /> },
    { path: routesEnum.FAVORITES, icon: <HeartIcon className={iconStyles} /> },
    { path: routesEnum.BASKET, icon: <ShoppingCartIcon className={iconStyles} /> },
  ];

  const fullAmount = useMemo(() => {
    if (!basket.length) {
      return 0;
    }

    return basket.reduce((sum, curr) => {
      return sum + curr.amount;
    }, 0);
  }, [basket]);

  return (
    <header>
      <div className="hidden d-xs:block">
        <Button classNames="fixed z-1000 top-4 left-1/2 -translate-x-1/2" disabled>
          <LogoIcon className="h-5 w-24" />
        </Button>

        {user && (
          <Button
            classNames="fixed z-1000 top-4 right-4"
            onClick={() => {
              if (!isUserLoading && !user) {
                navigate(routesEnum.LOGIN);
              }
              if (user) {
                navigate(routesEnum.PROFILE_ABOUT);
              }
            }}
          >
            {isUserLoading && <Loader classNames="w-4 h-4 mx-8" />}
            {!isUserLoading && user && (
              <>
                {user.name}
                {/*<div className="font-megasansj-500 mx-1 mt-0.5 text-green-100">254</div>*/}
                {/*<SberS variant="green" />*/}
              </>
            )}
            {!isUserLoading && !user && 'Войти'}
          </Button>
        )}
      </div>

      <div className="h-12 min-h-12 d-xs:hidden">
        <div
          className={cn(
            { 'relative border-white': isTop, 'fixed w-full border-grey-300': !isTop },
            'z-1000 flex h-12 min-h-12 items-center border-b bg-white px-4',
          )}
        >
          <button className="mr-3 h-5 w-5" onClick={() => setIsMenuOpen(true)}>
            <BurgerIcon className="fill-grey-500 p-0.5" />
          </button>
          <LogoIcon className="h-5 w-24" />

          {user && (
            <div className="ml-auto flex">
              {links.map(({ path, icon }) => (
                <Link
                  to={path}
                  key={`header-link-${path}`}
                  className="relative flex h-8 w-8 items-center justify-center"
                >
                  {icon}
                  {path === routesEnum.BASKET && fullAmount ? (
                    <div
                      className={cn(
                        'font-megasansj-400 absolute -right-0.5 -top-0.5 rounded-full px-1.5',
                        'bg-violet-100 py-0.5 text-extra-xs text-white',
                      )}
                    >
                      {fullAmount}
                    </div>
                  ) : null}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>

      {isMenuOpen &&
        createPortal(
          <div className="fixed inset-0 z-2000 bg-white p-4">
            <div className="mb-8 flex items-center justify-between">
              <div className="font-megasansj-400 text-xl">Меню</div>

              <button className="h-5 w-5" onClick={() => setIsMenuOpen(false)}>
                <CloseIcon className="fill-grey-500 p-0.5" />
              </button>
            </div>

            <ProfileNavigation onClose={() => setIsMenuOpen(false)} />
          </div>,
          document.body,
        )}
    </header>
  );
};
