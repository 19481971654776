import { FC, MouseEvent } from 'react';
import cn from 'classnames';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/kit/check.svg';

interface CheckBoxProps {
  isActive?: boolean;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  classNames?: string;
}

export const CheckBox: FC<CheckBoxProps> = ({ isActive, classNames, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        { 'bg-violet-100': isActive, 'bg-white': !isActive },
        'flex h-4 w-4 items-center justify-center rounded border border-grey-400',
        'flex-shrink-0 cursor-pointer transition-all group-hover:border-violet-100',
        classNames,
      )}
    >
      <CheckIcon className="h-2.5 w-2.5 stroke-white" />
    </div>
  );
};
