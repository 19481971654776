import React, { createContext, FC, ReactNode, useState } from 'react';
import { GroupType } from 'src/shared/api/catalog/types';
import { groupContextDefaultState } from 'src/shared/store/group/constants';
import { IGroupContext } from 'src/shared/store/group/types';

export const GroupContext = createContext<IGroupContext>(groupContextDefaultState);

interface ICatalogProviderProps {
  children: ReactNode;
}

export const GroupProvider: FC<ICatalogProviderProps> = ({ children }) => {
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [isGroupsLoading, setIsGroupsLoading] = useState<boolean>(true);

  return (
    <GroupContext.Provider
      value={{
        groups,
        setGroups,
        isGroupsLoading,
        setIsGroupsLoading,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};
