import { SortType } from 'src/shared/types';

export const emptyError = 'Поле не может быть пустым';

export enum routesEnum {
  LOGIN = '/login',
  REGISTER = '/register',
  CATALOG = '/catalog',
  BASKET = '/basket',
  PRODUCT = '/product/:id',
  PAYMENT = '/payment/:id',
  END_PAYMENT = '/end_payment',
  FAVORITES = '/favorites',
  PROFILE_ORDERS = '/profile/orders',
  PROFILE_ABOUT = '/profile/about',
  PROFILE_CUSTOMIZATION = '/profile/customization',
}

export const sort: SortType[] = [
  { value: 'name|asc', label: 'По имени' },
  { value: 'price|asc', label: 'Сначала дешевле' },
  { value: 'price|desc', label: 'Сначала дороже' },
  { value: 'rating|desc', label: 'Высокий рейтинг' },
];

export const initialInterval = { min: 0, max: 1000 };

export const marketLink = 'https://megamarket.ru/';

export const offsetStep = 12;
