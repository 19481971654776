// export const isDevApi = process.env.REACT_APP_BUILD === 'development';
export const isDevApi = true;

export const AUTH_URL = isDevApi
  ? 'https://breeze-bot-auth-dev.bots.winsolutions.ru'
  : 'https://breeze-bot-auth-dev.bots.winsolutions.ru';

export const CATALOG_URL = 'https://breeze-bot-catalog-dev.bots.winsolutions.ru';

export const PAYMENT_URL = 'https://breeze-bot-payment-back-dev.bots.winsolutions.ru';
