import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { routesEnum } from 'src/shared/const';

interface ProfileNavigationProps {
  onClose?: () => void;
}

export const ProfileNavigation: FC<ProfileNavigationProps> = ({ onClose }) => {
  const { pathname } = useLocation();

  const links = [
    { label: 'Личные данные', path: routesEnum.PROFILE_ABOUT },
    { label: 'Заказы', path: routesEnum.PROFILE_ORDERS },
    { label: 'Настройки профиля', path: routesEnum.PROFILE_CUSTOMIZATION },
  ];

  return (
    <div className="flex w-full flex-col gap-2.5">
      {links.map(link => (
        <Link
          onClick={onClose && onClose}
          key={link.path}
          to={link.path}
          className={cn(
            {
              'border-violet-100 text-black': pathname === link.path,
              'border-transparent text-grey-500 hover:border-violet-100 hover:text-black':
                pathname !== link.path,
            },
            'font-megasansj-400 border-l-2 pl-2 transition-all',
          )}
        >
          {link.label}
        </Link>
      ))}
    </div>
  );
};
