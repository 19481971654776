import { BasketPage } from 'src/pages/BasketPage';
import { CatalogPage } from 'src/pages/CatalogPage';
import { EndPaymentPage } from 'src/pages/EndPaymentPage';
import { LoginPage } from 'src/pages/LoginPage';
import { PaymentPage } from 'src/pages/PaymentPage';
import { ProductPage } from 'src/pages/ProductPage';
import { AboutPage, CustomizationPage, OrdersPage } from 'src/pages/Profile';
import { RegisterPage } from 'src/pages/RegisterPage';
import { routesEnum } from 'src/shared/const';
import { Route } from 'src/shared/types';

export const routes: Route[] = [
  {
    path: routesEnum.LOGIN,
    element: <LoginPage />,
  },
  {
    path: routesEnum.CATALOG,
    element: <CatalogPage />,
  },
  {
    path: routesEnum.BASKET,
    element: <BasketPage />,
  },
  {
    path: routesEnum.REGISTER,
    element: <RegisterPage />,
  },
  {
    path: routesEnum.PRODUCT,
    element: <ProductPage />,
  },
  {
    path: routesEnum.PAYMENT,
    element: <PaymentPage />,
  },
  {
    path: routesEnum.END_PAYMENT,
    element: <EndPaymentPage />,
  },
  {
    path: routesEnum.FAVORITES,
    element: <CatalogPage isFavorite />,
  },
  {
    path: routesEnum.PROFILE_ORDERS,
    element: <OrdersPage />,
  },
  {
    path: routesEnum.PROFILE_CUSTOMIZATION,
    element: <CustomizationPage />,
  },
  {
    path: routesEnum.PROFILE_ABOUT,
    element: <AboutPage />,
  },
];
