import { FC, useContext, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { CatalogContext } from 'src/app/providers/CatalogProvider';
import { GridFilter } from 'src/feature/GridFilter';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/kit/check.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { ReactComponent as FilterIcon } from 'src/shared/assets/icons/kit/filter.svg';
import { ReactComponent as GridIcon } from 'src/shared/assets/icons/kit/grid.svg';
import { ReactComponent as ListIcon } from 'src/shared/assets/icons/kit/list.svg';
import { ReactComponent as SortIcon } from 'src/shared/assets/icons/kit/sort.svg';
import { sort } from 'src/shared/const';
import { useClickOutside } from 'src/shared/lib/hooks';
import { SortType, StateActionType } from 'src/shared/types';
import { Button, Select } from 'src/shared/ui';

interface CatalogHeadProps {
  isGrid?: boolean;
  setIsGrid?: StateActionType<boolean>;
  selectedSort: SortType;
  setSelectedSort: StateActionType<SortType>;
}

export const CatalogHead: FC<CatalogHeadProps> = ({
  isGrid,
  setIsGrid,
  setSelectedSort,
  selectedSort,
}) => {
  const { resetFilters } = useContext(CatalogContext);

  const sortRef = useRef<HTMLDivElement>(null);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useClickOutside(sortRef, () => setIsSortOpen(false));

  return (
    <div className="mb-4 flex w-full flex-shrink-0 items-center border-b border-grey-400 pb-4">
      <div className="hidden w-64 d-xs:block">
        <Select options={sort} onChange={setSelectedSort} value={selectedSort} />
      </div>

      <button className="flex items-center d-xs:hidden" onClick={() => setIsSortOpen(true)}>
        <SortIcon className="mr-2 h-5 w-5 rotate-180 fill-black-100" />
        <div className="font-megasansj-400 text-black-100">{selectedSort.label}</div>
      </button>

      <button
        className="ml-auto flex items-center d-xs:hidden"
        onClick={() => setIsFilterOpen(true)}
      >
        <div className="font-megasansj-400 text-black-100">Фильтры</div>
        <FilterIcon className="ml-2 h-5 w-5 fill-black-100" />
      </button>

      {setIsGrid && (
        <div className="ml-auto hidden gap-2 d-xs:flex">
          <GridIcon
            onClick={() => setIsGrid(true)}
            className={cn(
              { 'fill-grey-500': isGrid, 'fill-grey-600 hover:fill-grey-500': !isGrid },
              'h-5 w-5 cursor-pointer',
            )}
          />
          <ListIcon
            onClick={() => setIsGrid(false)}
            className={cn(
              { 'fill-grey-500': !isGrid, 'fill-grey-600 hover:fill-grey-500': isGrid },
              'h-5 w-5 cursor-pointer',
            )}
          />
        </div>
      )}

      {isSortOpen &&
        createPortal(
          <div className="fixed inset-0 z-2000 flex h-full w-full bg-grey-600">
            <div className="mt-auto w-full rounded-t-3xl bg-white py-8" ref={sortRef}>
              {sort.map(option => (
                <div
                  className="flex w-full cursor-pointer items-center px-4 py-3"
                  onClick={() => {
                    setSelectedSort && setSelectedSort(option);
                    setIsSortOpen(false);
                  }}
                  key={option.value}
                >
                  <div
                    className={cn(
                      { 'text-violet-100': selectedSort?.value === option.value },
                      'font-megasansj-400',
                    )}
                  >
                    {option.label}
                  </div>

                  {selectedSort?.value === option.value && (
                    <CheckIcon className="ml-auto h-5 w-5 stroke-violet-100" />
                  )}
                </div>
              ))}
            </div>
          </div>,
          document.body,
        )}

      {isFilterOpen &&
        createPortal(
          <div className="fixed inset-0 z-2000 flex h-full w-full flex-col bg-white">
            <div className="flex flex-shrink-0 items-center justify-between border-b border-grey-400">
              <button onClick={() => setIsFilterOpen(false)} className="p-5">
                <CloseIcon className="h-3 w-3 fill-grey-500" />
              </button>

              <div className="font-megasansj-400 text-black-100">Фильтры</div>

              <button
                className="rounded-full px-4 py-1 text-sm text-violet-100 hover:bg-grey-300"
                onClick={() => {
                  setIsFilterOpen(false);
                  resetFilters();
                }}
              >
                Очистить
              </button>
            </div>

            <div className="flex-1 overflow-auto p-4">
              <GridFilter />
            </div>

            <div className="w-full flex-shrink-0 p-2.5" onClick={() => setIsFilterOpen(false)}>
              <Button variant="violet" size="sm" classNames="w-full">
                Показать товары
              </Button>
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};
