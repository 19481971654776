import { useContext, useEffect, useState } from 'react';
import { ProductType } from 'src/shared/api/catalog';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { offsetStep } from 'src/shared/const';
import { debounce } from 'src/shared/lib/utils';
import { CatalogContext, UserContext } from 'src/shared/store';
import { SortType } from 'src/shared/types';

export const useProducts = (selectedSort: SortType, isBought?: boolean, isFavorite?: boolean) => {
  const { filters, isLoadingInitialFilters, setFilters } = useContext(CatalogContext);
  const { user } = useContext(UserContext);

  const [products, setProducts] = useState<ProductType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const checkPosition = debounce(() => {
    const { clientHeight, scrollHeight } = document.body;

    const position = window.pageYOffset + clientHeight;
    const threshold = scrollHeight - position;

    if (threshold <= 200) {
      setFilters(prev => ({ ...prev, offset: prev.offset + offsetStep }));
    }
  }, 400);

  useEffect(() => {
    document.addEventListener('scroll', () => checkPosition());

    return () => {
      document.removeEventListener('scroll', () => checkPosition());
    };
  }, []);

  useEffect(() => {
    if (isLoadingInitialFilters) {
      setIsLoading(true);
    }
  }, [isLoadingInitialFilters]);

  useEffect(() => {
    if (!filters.selectedGroup || !user || isLoadingInitialFilters || filters.isAllLoading) {
      return;
    }

    (async () => {
      try {
        filters.offset === 0 && setIsLoading(true);

        const { data } = isFavorite
          ? await CatalogService.getFavoriteProducts(selectedSort.value, filters, user.id, isBought)
          : await CatalogService.getProducts(selectedSort.value, filters, user.id);

        if (!data?.length) {
          setFilters(prev => ({ ...prev, isAllLoading: true }));
        }

        if (filters.offset === 0) {
          setProducts(data);
        }

        if (data?.length && filters.offset !== 0) {
          setProducts(prev => [...prev, ...data]);
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [filters, isLoadingInitialFilters, selectedSort, isBought]);

  return { isLoading, products, setProducts };
};
