import { FC } from 'react';
import cn from 'classnames';

interface ImgProps {
  src: string;
  classNames?: string;
  alt?: string;
  noBg?: boolean;
}

export const Img: FC<ImgProps> = ({ classNames, src, noBg, alt = '' }) => {
  return (
    <img
      onLoad={e => {
        const img = e.target as HTMLImageElement;

        if (!img.src.includes('.gif')) {
          return;
        }
        const width = img.width;
        const height = img.height;
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d')?.drawImage(img, 0, 0, width, height);
        canvas.style.position = 'absolute';
        canvas.style.zIndex = '1';
        canvas.setAttribute('aria-hidden', 'true');
        canvas.setAttribute('role', 'presentation');
        const parent = img.parentNode;
        parent?.insertBefore(canvas, img);
      }}
      onError={e => {
        (e.target as HTMLImageElement).src =
          'https://jmiltd.com/wp-content/uploads/2016/08/ef3-placeholder-image.jpg';
      }}
      src={src}
      alt={alt}
      className={cn(
        { 'bg-grey-800': !Boolean(noBg) },
        'relative h-full w-full object-contain group-hover:z-10',
        classNames,
      )}
    />
  );
};
