import { useEffect, useMemo, useState } from 'react';

export const useSearchHistory = (search: string) => {
  const [list, setList] = useState<string[]>([]);

  useEffect(() => {
    const searchHistory = localStorage?.searchHistory
      ? JSON.parse(localStorage?.searchHistory)
      : [];

    setList(searchHistory);
  }, []);

  const filteredList = useMemo(() => {
    if (!search) {
      return list;
    }

    return list.filter((el: string) => el.toLowerCase().includes(search.toLowerCase()));
  }, [search, list]);

  const handleRemoveFromLocalStorage = (str: string) => {
    const searchHistory = localStorage?.searchHistory ? JSON.parse(localStorage.searchHistory) : [];
    localStorage.searchHistory = JSON.stringify(searchHistory.filter(el => el !== str));
    setList(prev => prev.filter(el => el !== str));
  };

  const handleSetSearchToLocaleStorage = (str: string) => {
    if (list.includes(str)) {
      return;
    }

    const searchHistory = localStorage?.searchHistory ? JSON.parse(localStorage.searchHistory) : [];
    searchHistory.push(str);
    setList(prev => [...prev, str]);
    localStorage.searchHistory = JSON.stringify([...new Set(searchHistory)]);
  };

  return {
    remove: handleRemoveFromLocalStorage,
    add: handleSetSearchToLocaleStorage,
    list: filteredList,
  };
};
